import { client, ContentTypes, ResponseData } from '../galaxyClient';
import { captureException } from '../tracking/sentry/sentry';

export type Video = ResponseData<ContentTypes, '/v2/videos/{id}', 'get'>;

export type MetaData = Video['metaData'];
export type Resource = Extract<Video['resources'], object>[0];

export const getVideoById = async (videoId?: string | number) => {
  try {
    if (videoId === undefined || videoId === null) {
      return;
    }
    try {
      const { data: video } = await client().content.GET('/v2/videos/{id}', {
        params: {
          path: {
            id: videoId,
          },
        },
      });

      return video;
    } catch (error) {
      captureException(new Error(`Failed getting video ${videoId}. Error: ${error}`));
      return;
    }
  } catch (error) {
    captureException(error);
  }
};
