import { Video } from '../api/videoApi';

const STREAMING_MIME_TYPES = ['application/x-mpegURL'];

/**
 * Enum for video orientations
 * @readonly
 * @enum {string}
 */
export enum VIDEO_ORIENTATIONS {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
  DEFAULT = 'DEFAULT',
}

/**
 * type for streaming video
 * @video {Video}
 * @orientation {VIDEO_ORIENTATIONS}
 * */
type StreamingVideoType = {
  video: {
    resources?: {
      src: string;
      mimeType: string;
      orientation?: string;
    }[];
    metaData?: {
      orientation?: string;
    };
  };
  orientation?: VIDEO_ORIENTATIONS;
};

/**
 * Tries to return a streaming src from a video, otherwise returns a src
 */
export const getStreamingSrc = ({
  video,
  orientation = VIDEO_ORIENTATIONS.DEFAULT,
}: StreamingVideoType) => {
  if (!video || !video.resources) {
    return;
  }

  const streamingResources = video.resources.filter((resource) =>
    STREAMING_MIME_TYPES.includes(resource.mimeType),
  );

  if (!streamingResources.length) {
    // Return first available src if streaming resources are available
    return (video.resources.length && video.resources[0].src) || undefined;
  }

  let orientationToReturn: string | undefined;

  if (orientation === VIDEO_ORIENTATIONS.DEFAULT) {
    orientationToReturn = video?.metaData?.orientation;
  } else {
    orientationToReturn = VIDEO_ORIENTATIONS[orientation]?.toLowerCase();
  }

  if (!orientationToReturn) {
    return streamingResources[0].src;
  }

  const orientedStreamingSrc = streamingResources.find(
    (resource) => resource.orientation === orientationToReturn,
  );

  return (orientedStreamingSrc || streamingResources[0]).src;
};

const aspectRatioForOrientation = {
  [VIDEO_ORIENTATIONS.DEFAULT]: undefined,
  [VIDEO_ORIENTATIONS.LANDSCAPE]: 16 / 9,
  [VIDEO_ORIENTATIONS.PORTRAIT]: 9 / 16,
};

export const getDefaultAspectRatio = (video: Video) => {
  return aspectRatioForOrientation[
    video?.metaData?.orientation?.toUpperCase() as keyof typeof aspectRatioForOrientation
  ];
};

export const getThumbnailSrc = (src?: string): string | undefined => {
  // Fix thumbnail to safari on mobile, add #t=[starttime][,endtime] to create media fragment
  // #t=0.01 will force player jump to 0.01 second from origin resource and create a thumbnails.
  return src ? `${src}#t=0.01` : undefined;
};
